










































































































































































import Vue from 'vue'

import { RootState } from 'HealthAdvisorFrontendApp'
import FacebookIcon from '~/static/icons/social-facebook.svg?inline'
import TwitterIcon from '~/static/icons/social-twitter.svg?inline'
import LogoBrand from '@/components/shared/LogoBrand/index.vue'
import { routes } from '@/utils/routes'
import { DEFAULT_SITE_URL } from '~/utils/settings'

type Location = {
  name: string
  url: string
}

export default Vue.extend({
  name: 'Footer',
  components: {
    FacebookIcon,
    TwitterIcon,
    LogoBrand
  },
  data() {
    return {
      routes,
      facebookScriptLoaded: false,
      siteUrl: ''
    }
  },
  computed: {
    getYear(): number {
      return new Date().getFullYear()
    },
    regions(): Location[] {
      const storeState = this.$store.state as RootState

      // no clinics means no locations to list, so if we don't have a good amout of clinics
      // let's mock footer data
      const fixedLocations = [
        'East England',
        'East Midlands',
        'London',
        'North East England',
        'North West England',
        'South East England',
        'South West England',
        'West Midlands',
        'Yorkshire and the Humber',
        'Northern Ireland',
        'Scotland',
        'Wales'
      ]

      const regions =
        storeState.regions && storeState.regions.length
          ? storeState.regions.map((r) => ({
              name: r.name,
              url: routes.region.makeUrl(r.slug)
            }))
          : fixedLocations.map((loc) => ({
              name: loc,
              url: routes.search.makeUrl()
            }))
      return regions || []
    },
    twitterSharerUrl(): string {
      return `https://twitter.com/intent/tweet?url=${this.$data.siteUrl}${this.$route.fullPath}`
    },
    facebookSharerUrl(): string {
      return `https://www.facebook.com/sharer.php?u=${this.$data.siteUrl}${this.$route.fullPath}`
    }
  },
  mounted() {
    if (window && window.location) {
      this.$data.siteUrl = window.location.origin || DEFAULT_SITE_URL
    }

    const FacebookWidget = (window as any).FB
    if (FacebookWidget && FacebookWidget.XFBML && FacebookWidget.XFBML.parse) {
      FacebookWidget.XFBML.parse()
    } else {
      window.addEventListener('scroll', this.loadFacebookScript)
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.loadFacebookScript)
  },
  methods: {
    makeSocialLinks(socialType: string) {
      const storeState = this.$store.state as RootState
      const socialNetworkData = (storeState.config.socialNetworks || []).find(
        (social) => social.type === socialType
      )
      return {
        url: (socialNetworkData && socialNetworkData.url) || '#',
        title: (socialNetworkData && socialNetworkData.title) || ''
      }
    },
    loadFacebookScript() {
      if (this.facebookScriptLoaded || window.scrollY < 200) {
        return
      }
      this.facebookScriptLoaded = true
      const fbScript = document.createElement('script')
      fbScript.setAttribute(
        'src',
        'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0'
      )
      document.head.appendChild(fbScript)
    }
  }
})
