






import Vue from 'vue'
export default Vue.extend({
  name: 'HTMLRenderer',
  props: {
    htmlString: {
      type: String,
      default: '',
      required: true
    },
    inline: {
      type: Boolean,
      default: false
    }
  }
})
