import axios from 'axios'
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import {
  RootState,
  ConfigState,
  RegionState,
  LocationState,
  Actor,
  PartialActor
} from 'HealthAdvisorFrontendApp'
import { RegionListData, Locations } from 'HealthAdvisorBackendApp'
import Cookies from 'js-cookie'
import {
  SOCIAL_NETWORKS,
  DEFAULT_COORDS,
  DEFAULT_SEO_DATA
} from '@/utils/settings'
import { NuxtSeoHead, SchemaJsonLD } from '@/utils/api/seo'

export const state = (): RootState => ({
  config: {},
  regions: [],
  locations: {},
  topLocations: {},
  defaultSeoHead: {},
  defaultSchemaJsonLD: undefined,
  actor: undefined,
  oktaSiginWidget: undefined
})

export const getters: GetterTree<RootState, RootState> = {}

export const mutations: MutationTree<RootState> = {
  SET_OKTA_SIGNIN_WIDGET(state, oktaSiginWidget: any) {
    state.oktaSiginWidget = oktaSiginWidget
  },
  SET_CONFIG(state, config: ConfigState) {
    state.config = config
  },
  SET_REGIONS(state, regions: RegionState) {
    state.regions = regions
  },
  SET_LOCATIONS(state, locations: LocationState) {
    state.locations = locations
  },
  SET_TOP_LOCATIONS(state, topLocations: LocationState) {
    state.topLocations = topLocations
  },
  SET_SEO_HEAD(state, seoHead: NuxtSeoHead) {
    state.defaultSeoHead = seoHead
  },
  SET_SEO_SCHEMA_JSONLD(state, schemaJsonLD: SchemaJsonLD) {
    state.defaultSchemaJsonLD = schemaJsonLD
  },
  SET_ACTOR(state, actor: Actor) {
    state.actor = actor
  },
  SET_USER_COOKIE(_state, actor: Actor | PartialActor) {
    if (actor && actor.providerId) {
      const minutes = 60
      const userCokieExpiration = new Date(
        new Date().getTime() + minutes * 60 * 1000
      )
      Cookies.set(
        'user',
        JSON.stringify({
          providerId: actor.providerId,
          internalId: actor.internalId || '',
          firstName: actor.firstName,
          lastName: actor.lastName,
          email: actor.email
        }),
        { expires: userCokieExpiration }
      )
    }
  },
  REMOVE_USER_COOKIE(_state) {
    Cookies.remove('user')
  }
}

export const actions: ActionTree<RootState, RootState> = {
  setOktaSiginWidget({ commit }, oktaSiginWidget: any) {
    commit('SET_OKTA_SIGNIN_WIDGET', oktaSiginWidget)
  },
  setActorData({ commit }, actor: Actor) {
    commit('SET_ACTOR', actor)
    if (actor.signupStep && ['1', '2', '3'].includes(actor.signupStep)) {
      commit('SET_USER_COOKIE', actor)
    }
  },
  flushActor({ commit }, keepCookie: boolean) {
    commit('SET_ACTOR', undefined)
    if (!keepCookie) {
      commit('REMOVE_USER_COOKIE')
    }
  },
  createUserCookie({ commit }, partialActor: PartialActor) {
    commit('SET_USER_COOKIE', partialActor)
  },
  removeUserCookie({ commit }) {
    commit('REMOVE_USER_COOKIE')
  },
  async nuxtServerInit({ commit }) {
    // Setting Axios options
    const apiBaseUrl = process.env.API_PROXY_URL
      ? `${process.env.API_PROXY_URL}/backend/api/v1`
      : `${process.env.API_URL}`

    const axiosOptions = process.env.API_BASIC_AUTH
      ? {
          headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Basic ' + process.env.API_BASIC_AUTH
          }
        }
      : {
          headers: {
            Accept: 'application/json, text/plain, */*'
          }
        }

    // default config data
    const config: ConfigState = {
      defaultCoords: DEFAULT_COORDS,
      socialNetworks: SOCIAL_NETWORKS
    }
    commit('SET_CONFIG', config)

    // default seo data
    const headData = DEFAULT_SEO_DATA
    commit('SET_SEO_HEAD', headData)

    // fetching regions, location, top locations
    const apiRegionsUrl = `${apiBaseUrl}/regions/`
    const apiLocationsUrl = `${apiBaseUrl}/locations/`

    let apiRegions
    let apiLocations

    try {
      const allApiData = await Promise.all([
        axios.get(apiRegionsUrl, axiosOptions),
        axios.get(apiLocationsUrl, axiosOptions)
      ])
      ;[apiRegions, apiLocations] = allApiData
    } catch {
      // eslint-disable-next-line no-console
      console.warn('Could not fetch locations from store - promise all failed')
    }

    // committing fetched regions data to store
    const apiRegionsResponseData: RegionListData =
      apiRegions && apiRegions.data && apiRegions.data.data
    const regions: RegionState =
      apiRegionsResponseData && apiRegionsResponseData.length
        ? apiRegionsResponseData.map((r) => ({
            name: r.name,
            slug: r.url
              .split('/')
              .filter((part) => !!part)
              .reverse()[0]
          }))
        : []
    commit('SET_REGIONS', regions)

    // committing fetched config data to store
    const apiLocationsResponseData: Locations =
      apiLocations && apiLocations.data && apiLocations.data.data
    const locations: LocationState = apiLocationsResponseData
    commit('SET_LOCATIONS', locations)
  }
}
