import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e54e84ec = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _64703fa1 = () => interopDefault(import('../pages/articles-search/index.vue' /* webpackChunkName: "pages/articles-search/index" */))
const _9c378998 = () => interopDefault(import('../pages/clinics/index.vue' /* webpackChunkName: "pages/clinics/index" */))
const _18870e63 = () => interopDefault(import('../pages/holding-page.vue' /* webpackChunkName: "pages/holding-page" */))
const _4681d5fc = () => interopDefault(import('../pages/location/index.vue' /* webpackChunkName: "pages/location/index" */))
const _c65dbfdc = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _62d0eb81 = () => interopDefault(import('../pages/osteopaths/index.vue' /* webpackChunkName: "pages/osteopaths/index" */))
const _20d163fe = () => interopDefault(import('../pages/region/index.vue' /* webpackChunkName: "pages/region/index" */))
const _0ee1bbb5 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _39193b25 = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _fd169622 = () => interopDefault(import('../pages/clinics/makeClinicsListingSchemaJsonLD.ts' /* webpackChunkName: "pages/clinics/makeClinicsListingSchemaJsonLD" */))
const _1bf907f5 = () => interopDefault(import('../pages/signup/add-clinics/index.vue' /* webpackChunkName: "pages/signup/add-clinics/index" */))
const _6ea853b0 = () => interopDefault(import('../pages/signup/add-payment/index.vue' /* webpackChunkName: "pages/signup/add-payment/index" */))
const _002f396c = () => interopDefault(import('../pages/signup/onboardingSettings.ts' /* webpackChunkName: "pages/signup/onboardingSettings" */))
const _055bf75c = () => interopDefault(import('../pages/signup/payment/index.vue' /* webpackChunkName: "pages/signup/payment/index" */))
const _2355efa3 = () => interopDefault(import('../pages/signup/register/index.vue' /* webpackChunkName: "pages/signup/register/index" */))
const _c66525ce = () => interopDefault(import('../pages/signup/success/index.vue' /* webpackChunkName: "pages/signup/success/index" */))
const _5bd05984 = () => interopDefault(import('../pages/signup/add-clinics/validateForm.ts' /* webpackChunkName: "pages/signup/add-clinics/validateForm" */))
const _cf307f28 = () => interopDefault(import('../pages/signup/register/validateForm.ts' /* webpackChunkName: "pages/signup/register/validateForm" */))
const _1a5f0f50 = () => interopDefault(import('../pages/articles/_category/index.vue' /* webpackChunkName: "pages/articles/_category/index" */))
const _e83c0122 = () => interopDefault(import('../pages/clinics/_slug/index.vue' /* webpackChunkName: "pages/clinics/_slug/index" */))
const _03dd047c = () => interopDefault(import('../pages/region/_slug/index.vue' /* webpackChunkName: "pages/region/_slug/index" */))
const _3c4af44a = () => interopDefault(import('../pages/clinics/_slug/makeClinicSchemaJsonLD.ts' /* webpackChunkName: "pages/clinics/_slug/makeClinicSchemaJsonLD" */))
const _148dcafb = () => interopDefault(import('../pages/activate/_key/_userId/index.vue' /* webpackChunkName: "pages/activate/_key/_userId/index" */))
const _432cd7e4 = () => interopDefault(import('../pages/articles/_category/_article/index.vue' /* webpackChunkName: "pages/articles/_category/_article/index" */))
const _0791523e = () => interopDefault(import('../pages/location/_county/_town/index.vue' /* webpackChunkName: "pages/location/_county/_town/index" */))
const _914b8232 = () => interopDefault(import('../pages/osteopaths/_slug/_id/index.vue' /* webpackChunkName: "pages/osteopaths/_slug/_id/index" */))
const _53dc28ac = () => interopDefault(import('../pages/articles/_category/_article/makeArticleSchemaJsonLD.ts' /* webpackChunkName: "pages/articles/_category/_article/makeArticleSchemaJsonLD" */))
const _1ef70db8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f274421a = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/articles",
    component: _e54e84ec,
    name: "articles___en"
  }, {
    path: "/articles-search",
    component: _64703fa1,
    name: "articles-search___en"
  }, {
    path: "/clinics",
    component: _9c378998,
    name: "clinics___en"
  }, {
    path: "/holding-page",
    component: _18870e63,
    name: "holding-page___en"
  }, {
    path: "/location",
    component: _4681d5fc,
    name: "location___en"
  }, {
    path: "/login",
    component: _c65dbfdc,
    name: "login___en"
  }, {
    path: "/osteopaths",
    component: _62d0eb81,
    name: "osteopaths___en"
  }, {
    path: "/region",
    component: _20d163fe,
    name: "region___en"
  }, {
    path: "/search",
    component: _0ee1bbb5,
    name: "search___en"
  }, {
    path: "/signup",
    component: _39193b25,
    name: "signup___en"
  }, {
    path: "/clinics/makeClinicsListingSchemaJsonLD",
    component: _fd169622,
    name: "clinics-makeClinicsListingSchemaJsonLD___en"
  }, {
    path: "/signup/add-clinics",
    component: _1bf907f5,
    name: "signup-add-clinics___en"
  }, {
    path: "/signup/add-payment",
    component: _6ea853b0,
    name: "signup-add-payment___en"
  }, {
    path: "/signup/onboardingSettings",
    component: _002f396c,
    name: "signup-onboardingSettings___en"
  }, {
    path: "/signup/payment",
    component: _055bf75c,
    name: "signup-payment___en"
  }, {
    path: "/signup/register",
    component: _2355efa3,
    name: "signup-register___en"
  }, {
    path: "/signup/success",
    component: _c66525ce,
    name: "signup-success___en"
  }, {
    path: "/signup/add-clinics/validateForm",
    component: _5bd05984,
    name: "signup-add-clinics-validateForm___en"
  }, {
    path: "/signup/register/validateForm",
    component: _cf307f28,
    name: "signup-register-validateForm___en"
  }, {
    path: "/articles/:category",
    component: _1a5f0f50,
    name: "articles-category___en"
  }, {
    path: "/clinics/:slug",
    component: _e83c0122,
    name: "clinics-slug___en"
  }, {
    path: "/region/:slug",
    component: _03dd047c,
    name: "region-slug___en"
  }, {
    path: "/clinics/:slug/makeClinicSchemaJsonLD",
    component: _3c4af44a,
    name: "clinics-slug-makeClinicSchemaJsonLD___en"
  }, {
    path: "/activate/:key?/:userId?",
    component: _148dcafb,
    name: "activate-key-userId___en"
  }, {
    path: "/articles/:category/:article",
    component: _432cd7e4,
    name: "articles-category-article___en"
  }, {
    path: "/location/:county/:town?",
    component: _0791523e,
    name: "location-county-town___en"
  }, {
    path: "/osteopaths/:slug/:id?",
    component: _914b8232,
    name: "osteopaths-slug-id___en"
  }, {
    path: "/articles/:category/:article/makeArticleSchemaJsonLD",
    component: _53dc28ac,
    name: "articles-category-article-makeArticleSchemaJsonLD___en"
  }, {
    path: "/",
    component: _1ef70db8,
    name: "index___en"
  }, {
    path: "/:slug",
    component: _f274421a,
    name: "slug___en"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
