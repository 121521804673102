<template>
  <div v-if="loading" class="loading-spinner-wrapper">
    <b-overlay
      :show="true"
      variant="transparent"
      spinner-variant="secondary"
      blur="1px"
      size="lg"
      no-wrap
    ></b-overlay>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false
  }),
  methods: {
    start() {
      this.loading = true
    },
    finish() {
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.loading-spinner-wrapper {
  position: fixed;
  z-index: $zindex-tooltip;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);

  .spinner-border {
    width: 80px;
    height: 80px;
  }
}
</style>
