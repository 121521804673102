










































import Vue from 'vue'
import LandingPageHero from '@/components/shared/LandingPageHero/index.vue'

export default Vue.extend({
  name: 'HoldingPage',
  components: {
    LandingPageHero
  },
  computed: {
    getYear(): number {
      return new Date().getFullYear()
    }
  }
})
