import { PRACTITIONERS_URL, PRACTITIONERS_LABEL } from './settings'

type Route = {
  path: string
}

type RouteBase = Route & {
  makeUrl: () => string
}

type RouteLabel = {
  label: string
}

type ArticleCategoryMakeUrl = {
  makeUrl: (categorySlug: string) => string
}
type ArticleMakeUrl = {
  makeUrl: (categorySlug: string, articleSlug: string) => string
}

type PractitionerMakeUrl = {
  makeUrl: (slug: string, id: string) => string
}

type ClinicMakeUrl = {
  makeUrl: (slug: string) => string
}

type RegionMakeUrl = {
  makeUrl: (slug: string) => string
}

type LocationPlaceMakeUrl = {
  // fullPath is because api returns a slug like `essex/basildon`
  makeUrl: (fullPath: string) => string
}

type Routes = {
  home: RouteBase
  about: RouteBase
  privacyPolicy: RouteBase
  membershipTerms: RouteBase
  termsAndConditions: RouteBase
  articles: RouteBase & RouteLabel
  articlesCategory: Route & ArticleCategoryMakeUrl
  article: Route & ArticleMakeUrl
  articlesSearch: RouteBase
  login: RouteBase
  signup: RouteBase & RouteLabel
  onboardingStep1: RouteBase
  onboardingStep2: RouteBase
  onboardingStep3: RouteBase
  onboardingStep3BeforeDirectDebit: RouteBase
  search: RouteBase
  practitioners: RouteBase & RouteLabel
  practitioner: Route & PractitionerMakeUrl
  clinics: RouteBase & RouteLabel
  clinic: Route & ClinicMakeUrl
  locations: RouteBase
  locationPlace: Route & LocationPlaceMakeUrl
  region: Route & RegionMakeUrl
}

export const routes: Routes = {
  home: {
    path: '/',
    makeUrl: () => '/'
  },
  about: {
    path: '/about/',
    makeUrl: () => '/about/'
  },
  privacyPolicy: {
    path: '/privacy-policy/',
    makeUrl: () => '/privacy-policy/'
  },
  membershipTerms: {
    path: '/membership-terms/',
    makeUrl: () => '/membership-terms/'
  },
  termsAndConditions: {
    path: '/website-terms-and-conditions/',
    makeUrl: () => '/website-terms-and-conditions/'
  },
  articles: {
    path: '/articles/',
    makeUrl: () => '/articles/',
    label: 'Health A-Z'
  },
  articlesCategory: {
    path: '/articles/:category/',
    makeUrl: (categorySlug) => `/articles/${categorySlug}/`
  },
  article: {
    path: '/articles/:category/:article/',
    makeUrl: (categorySlug, articleSlug) =>
      `/articles/${categorySlug}/${articleSlug}/`
  },
  articlesSearch: {
    path: '/articles-search/',
    makeUrl: () => '/articles-search/'
  },
  signup: {
    path: '/signup/',
    makeUrl: () => '/signup/',
    label: 'List your practice'
  },
  onboardingStep1: {
    path: '/signup/register/',
    makeUrl: () => '/signup/register/'
  },
  onboardingStep2: {
    path: '/signup/add-clinics/',
    makeUrl: () => '/signup/add-clinics/'
  },
  onboardingStep3BeforeDirectDebit: {
    path: '/signup/add-payment/',
    makeUrl: () => '/signup/add-payment/'
  },
  onboardingStep3: {
    path: '/signup/payment/',
    makeUrl: () => '/signup/payment/'
  },
  login: {
    path: '/login/',
    makeUrl: () => '/login/'
  },
  search: {
    path: '/search/',
    makeUrl: () => '/search/'
  },
  practitioners: {
    path: `/${PRACTITIONERS_URL}/`,
    makeUrl: () => `/${PRACTITIONERS_URL}/`,
    label: PRACTITIONERS_LABEL
  },
  practitioner: {
    path: `/${PRACTITIONERS_URL}/`,
    makeUrl: (slug, id) => `/${PRACTITIONERS_URL}/${slug}/${id}/`
  },
  clinics: {
    path: '/clinics/',
    makeUrl: () => '/clinics/',
    label: 'Clinics'
  },
  clinic: {
    path: '/clinics/',
    makeUrl: (slug) => `/clinics/${slug}/`
  },
  locations: {
    path: '/location/',
    makeUrl: () => `/location/`
  },
  locationPlace: {
    path: '/location/:county/:slug/',
    makeUrl: (fullpath: string) => `/location/${fullpath}/`
  },
  region: {
    path: '/region/:region/',
    makeUrl: (slug) => `/region/${slug}/`
  }
}
