












import Vue from 'vue'
import { RootState } from 'HealthAdvisorFrontendApp'
import AppFooter from '@/components/layout/Footer/index.vue'
import HoldingPage from '@/pages/holding-page.vue'

export default Vue.extend({
  components: {
    AppFooter,
    HoldingPage
  },
  data() {
    return {
      comingSoon: process.env.COMING_SOON === 'true'
    }
  },
  computed: {
    hideFooter() {
      const isInLogin = this.$route.path.includes('/login')
      const isInSearch = this.$route.path.includes('/search')

      // in following paths we want to show footer in root (eg: /signup/) but not in inner pages (eg: /signup/step1/)
      const isInOnboardingSteps =
        this.$route.path.includes('/signup/') && this.$route.path !== '/signup/'
      const isInLocation =
        this.$route.path.includes('/location/') &&
        this.$route.path !== '/location/'

      return isInLogin || isInOnboardingSteps || isInSearch || isInLocation
    }
  },
  head() {
    const storeState = this.$store.state as RootState
    return storeState.defaultSeoHead
  }
})
