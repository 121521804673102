




























import Vue from 'vue'

export default Vue.extend({
  name: 'LandingPageHero',
  functional: true,
  props: {
    heroImageUrl: {
      type: String,
      default: undefined
    },
    heroImageAltText: {
      type: String,
      default: undefined
    }
  }
})
