









































import Vue from 'vue'
import LandingPageHero from '@/components/shared/LandingPageHero/index.vue'

export default Vue.extend({
  components: {
    LandingPageHero
  },
  props: ['error'],
  computed: {
    getYear(): number {
      return new Date().getFullYear()
    }
  },
  methods: {
    navigateTo(to: string): void {
      this.$router.push({ path: to })
    }
  }
})
